#LogoDashboard {
  width: 7rem;
}

#list-menu-default a {
  text-decoration: none;
  border-radius: 0 3rem 3rem 0;
  margin-left: 0.1rem;
  font-size: 1rem;
}

#list-menu-default .list-group-item {
  border: none;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

#list-menu-default .list-group-item span {
  font-size: 1rem;
}

#list-menu-default .list-group-item.active {
  color: white;
  background-color: #d0e7f6;
  border-radius: 0 3rem 3rem 0;
  margin-left: 0.1rem;
}

#configuracoes-sistema.active {
  color: white;
}

#container-app-default aside {
  height: 100%;
}

#container-cards-conf-sistema .card {
  height: 8rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background: #f4f8fc;
  /* $color-medium */

  border: 1px solid #d8d8d8;
  /* Elevation/1 */

  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2),
    0px 1px 3px 1px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
}

#container-cards-conf-sistema .card a {
  text-decoration: none;
}

.selected-sidebar-item .btn {
  color: #0e5a8c;
}

.selected-sidebar-item .btn:not(:first-child) {
  color: #0e5a8c;
}

/* Define as regras de estilo para a nova <div> da barra lateral quando aberta */
.sidebar-wrapper.show {
  left: 0;
}

/* Define as regras de estilo para os links na barra lateral */
.sidebar-link {
  color: #333;
  padding: 10px;
  text-align: left;
}

/* Define as regras de estilo para o dropdown na barra lateral */
.sidebar-dropdown .dropdown-menu {
  position: static;
  float: none;
  width: auto;
  margin-top: 0;
  background-color: transparent;
  border: 0;
  box-shadow: none;
}

/* Define as regras de estilo para o dropdown toggle na barra lateral */
.sidebar-dropdown .dropdown-toggle {
  color: #333;
  text-align: left;
  padding: 10px;
  display: block;
}

/* Define as regras de estilo para a sobreposição de tela inteira */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1030;
}
