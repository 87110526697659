#logo-login {
  width: 8rem;
}

.bg-login {
  background-image: url("../../Assets/Images/LoginImage.png");
  background-size: cover;
  height: 100vh;

  position: relative;
}

.bg-pwd-recovery {
  background-image: url("../../Assets/Images/RecoverPassword.jpeg");
  background-size: cover;
  height: 100vh;
  position: relative;
}

.bg-change-pwd {
  background-image: url("../../Assets/Images/ChangePassword.png");
  background-size: cover;
  height: 100vh;
  position: relative;
}

.input-email {
  background-image: url("../../Assets/Images/email-9-svgrepo-com.svg");
  background-position: 98% center;
  background-size: 1.3rem;
  background-repeat: no-repeat;
  padding-left: 2em;
  padding-top: 10px;
  padding-bottom: 10px;
}

.input-user {
  background-image: url("../../Assets/Images/user.png");
  background-position: 98% center;
  background-size: 1.5rem;
  background-repeat: no-repeat;
  padding-left: 2em;
  padding-top: 10px;
  padding-bottom: 10px;
}

.input-password {
  background-image: url("../../Assets/Images/lock.png");
  background-position: 98% center;
  background-size: 1.3rem;
  background-repeat: no-repeat;
  padding-left: 2em;
  padding-top: 10px;
  padding-bottom: 10px;
}
